.MuiDrawer-paper{
    background-color: "pink";
}



.dashboard__parent {
    /* boxsizing: "border-box",
    width: drawerWidth,
    border: "1px solid #C7E6C5",
    borderTop: "none",
    marginTop: "75px",
    paddingTop: "0px !important", */
    
}