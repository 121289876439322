@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.table-body {
  margin-top: 30px;
}

.table-body > table {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  border-collapse: collapse;
  width: 100%;
}

.table-body > table > thead {
  background: #50b14a;
  border-radius: 3px 3px 0px 0px;
  color: #ffffff;
  text-transform: none;
}

.table-body > table > thead > tr,
.table-body > table > thead > tr > th {
  padding: 8px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  text-align: left;
  /* white */

  color: #ffffff;
  text-transform: none;

}


tbody tr{
    border: 0.5px solid #50B14A;
    cursor: pointer;
}
tbody tr:hover{
  background: #E6F4E5;
}


tbody > tr > td {
  padding: 8px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #666666;
  text-transform: none;
}

.main-parent{
    display: flex;
    justify-content: space-between;
    border: 1px solid #50B14A;
box-sizing: border-box;
padding: 5px;
}

.paginateButtons{
    background-color: rgba(230, 244, 229, 0.5);
    border: 1px solid rgba(80, 177, 74, 0.5);
    cursor: pointer;
    padding: 6px 12px;

  }

  .paginateButtons:hover{
    opacity: .8;
  }

  .main-father{
      display: flex;
      justify-content: space-between;
      /* background: #50b14a; */
      width: 100%;
     padding: 0 10px;
  }

  .each{
    padding: 10px;
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid #E6F4E5 ;
  }
  .each:hover{
    background: #E6F4E5;
  }

  .parent-right{
      display: flex;
      align-items: center;
  }

  .bottom-parent{
      display: flex;
      justify-content: space-between;

  }

.table_item_image{
  margin-left: 10px;
  width: 70px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
    /* border: 1px solid whitesmoke; */
    cursor: pointer;
}