.search-container {
    display: flex;
    align-items: center;
    border: 1px solid #879596;
    width: 400px;
    background: none;
    padding: 0 15px;
    border-radius: 5px;
}

.none-style {
    display: none;
}

.table_logo{
    max-width: 80px;
}
/* .wrapper>div {

    padding: 10px;
    border: 1px solid gray;
    width: 100%;
    outline: none;
    border: none;
    background: none;
} */



.search-input::placeholder {
    font-style: italic;
    color: gray;
}

.paginated__container {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table-filter-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 15px;
    background: #fafafa;
}

.search-input {
    flex: 1;
    padding: 10px;
    border: 1px solid gray;
    width: 100%;
    outline: none;
    border: none;
    background: none;
}

/* Dispute btn */
.dispute__btn {
    padding: 10px 13px;
    background: var(--Dillivry-Green);
    border-radius: 2px;
    border: none;
    outline: none;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
}



a,
a:hover,
a:focus {
    text-decoration: none;
    transition: 0.5s;
    outline: none;
}

.pagination-data {
    padding: 0;
    margin: 0;
}

.pagination-data li {
    list-style: none;
}

.card {
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
    background-color: #fff;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #475f7b;
    overflow: hidden;
}


.rc-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
    margin-right: 8px;
}

.rc-pagination-total-text {
    margin-right: 12px;
    cursor: initial;
}

.rc-pagination-jump-next,
.rc-pagination-jump-prev,
.rc-pagination-next,
.rc-pagination-prev {
    display: inline-block;
    min-width: 28px;
    height: 28px;
    color: rgba(0, 0, 0, .85);
    font-family: Arial;
    line-height: 28px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: 2px;
    cursor: pointer;
    transition: all .3s;
}

.rc-pagination-jump-next button,
.rc-pagination-jump-prev button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: #666;
}

.rc-pagination-jump-next button:after,
.rc-pagination-jump-prev button:after {
    display: block !important;
    content: "•••" !important;
}

.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-total-text {
    min-width: initial;
    height: auto;
    line-height: initial;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.rc-pagination-item a,
.rc-pagination-item button,
.rc-pagination-prev a,
.rc-pagination-prev button,
.rc-pagination-next a,
.rc-pagination-next button,
.rc-pagination-total-text a,
.rc-pagination-total-text button {
    padding: 6px 8px;
    height: auto;
    min-width: 32px;
    min-height: 32px;
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    color: #656f84 !important;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
}

.rc-pagination-item.rc-pagination-item-active a,
.rc-pagination-item.rc-pagination-item-active a:hover,
.rc-pagination-prev.rc-pagination-item-active a,
.rc-pagination-prev.rc-pagination-item-active a:hover,
.rc-pagination-next.rc-pagination-item-active a,
.rc-pagination-next.rc-pagination-item-active a:hover,
.rc-pagination-total-text.rc-pagination-item-active a,
.rc-pagination-total-text.rc-pagination-item-active a:hover {
    /* background-color: #ffffff; */
    border-color: #ffffff;
    color: #545b64 !important;
    font-weight: 600;
}

.rc-pagination-item a:hover,
.rc-pagination-item button:hover,
.rc-pagination-prev a:hover,
.rc-pagination-prev button:hover,
.rc-pagination-next a:hover,
.rc-pagination-next button:hover,
.rc-pagination-total-text a:hover,
.rc-pagination-total-text button:hover {
    background-color: #eceff5;
    border-color: #eceff5;
}

.corp_img {
    width: 40px;
    height: 40px;
    text-align: center;
    object-fit: cover;
    color: transparent;
    text-indent: 10000px;
}


.top_action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.first_item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.second_item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid #545b64;
    padding: 5px 40px;
    background: #ffffff;
}

.action_element {
    /* padding: 5px 16px; */
    padding: 5px 18px;
}


.ul_action li {
    list-style: none;
    cursor: pointer;

}

.search_ul_action li {
    list-style: none;
    cursor: pointer;
}

.action_element>ul li {
    font-family: 'Amazon Ember', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #16191f;
    list-style-type: none;
    margin-bottom: 3px;
    cursor: pointer;
}

.third_item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.refresh_icon {
    border: 1px solid #545b64;
    padding: 9px 16px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.time_fetched,
.action {
    font-family: 'Amazon Ember', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #545b64;
    cursor: pointer;
}

.create_btn {
    background: var(--Dillivry-Green);
    color: #ffffff;
    outline: none;
    padding: 10px 16px;
    border: none;
    cursor: pointer;
    font-family: 'Amazon Ember', sans-serif;
    font-size: 14px;
    font-weight: 400;
}


tbody tr td {
    font-family: 'Amazon Ember', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #16191f;
    line-height: 22px;
    /* border: 1px solid red; */
}

.table__row td{
    margin-bottom: 20px;
    padding: 15px;
}

th input {
    padding: 15px 10px;
}

.account_name_tag {
    color: #0073db
}

.action_container {
    /* display: flex;
    flex-direction: column; */
    position: relative;

}

.action_element {
    position: absolute;
    z-index: 1000;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    background: #ffffff;
}

.search-action_elementone {
    /* position: absolute;
    z-index: 1000;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    background: #ffffff;
    width: 400px;
     */
    position: absolute;
    z-index: 1000;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    background: #ffffff;
    width: 400px;
    padding: 30px;
}

.search-action_elementtwo {
    position: absolute;
    z-index: 1000;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    background: #ffffff;
    width: 400px;
    /* padding: 30px; */
}

.propertiesTag {
    font-family: 'Amazon Ember', sans-serif;
    color: #545b64;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    padding: 10px 30px 0px 10px;
}

.serach_title {
    font-family: 'Amazon Ember', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #16191f;
    line-height: 22px;
    padding: 0px 30px 30px 20px;
    border: 1px solid #eaeded;
    border-bottom: 1px solid #eaeded;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
}

.filtered_name {
    list-style: none;
    margin-bottom: 10px;
    /* border: 1px solid #eaeded; */
    cursor: pointer;
    user-select: none;
}

.account_name_class {
    color: #0073db;
}

.times {
    cursor: pointer;
}


.title_name {
    /* color: #0073db !important; */
    cursor: pointer !important;
}

.last_icon_class {
    cursor: pointer;
}

.search_ul_action{
    overflow-y: scroll;
    height: 190px;
}