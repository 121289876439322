.table__link {
  color: #333;
}

/* DISPUTE LANDING PAGE STYLING */
/* .dispute__resolution--container {
  margin-top: 40px;
} */

.dispute__top--container {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 5px 0 10px 0;
}

.dispute__top--container h2 {
  font-size: 18px;
  font-weight: 400;
}

.dispute__new--btn {
  align-items: flex-end;
  padding: 10px 13px;
  background: var(--Dillivry-Green);
  border-radius: 7px;
  border: none;
  outline: none;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}

.dispute__resolution--stats {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 70px;
}

.dispute__children {
  border: 1px solid #ccc;
  padding: 15px 13px;
  border-radius: 5px;
  width: 25%;
  height: 170px;
}

.dispute__children h3,
.dispute__resolution--table h2 {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 10px;
}

.dispute__children span {
  font-size: 12px;
}

.dispute__children p {
  font-size: 20px;
  margin: 15px 0;
}

.dispute__children a {
  font-size: 13px;
  margin-top: 10px;
}

.table__row a td {
  font-size: 12px;
  font-weight: 400;
}

/* END OF STYLING*/

.dispute__form--container {
  display: flex;
  flex-direction: column;
}

.dispute__form--container input,
.dispute__form--container textarea,
.dispute__form--container select {
  width: 100%;
  padding: 10px;
  font-size: 12px;
  outline: none;
  resize: none;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.dispute__form--container button {
  background: var(--Dillivry-Green);
  display: inline-block;
  font-size: 14px;
  border-radius: 5px;
  padding: 11px 16px;
  color: #fff;
  transition: all ease-in-out 0.5s;
  border: none;
  outline: none;
  cursor: pointer;
  /* width: 40%; */
}

.upload-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.custom-file-upload {
  display: inline-block;
  padding: 7px;
  cursor: pointer;
  color: #fff;
  background-color: var(--Dillivry-Green);
  border: none;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;
  transition: background-color 0.2s;
}

input[type="file"] {
  display: none;
}

.preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 30px;
}

.image-preview {
  width: 120px;
  height: 120px;
  position: relative;
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-preview::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.2s;
}

.image-preview:hover::before {
  opacity: 1;
}

.image-preview i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 48px;
  opacity: 0;
  transition: opacity 0;
}

/* Dispute Settlement Page Styling */
.dispute__settlement--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 26%;
  padding: 30px 40px;
  gap: 40px;
  width: 650px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.dispute__settlement--container__description {
  text-align: center;
  margin-bottom: 40px;
}

.dispute__settlement--container__description h2 {
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 10px;
}

.dispute__settlement--container__description p{
  font-weight: 300;
  font-size: 14px;
}

.dispute__settlement--container__information {
  margin-top: 20px;
}

.dispute__settlement--container__information div {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 300;
}

.disputedrilldown__child {
  border-bottom: 0.3px solid #ccc;
}

.dispute__complaint--para {
  flex-direction: column;
}

.dispute__complaint--para p:last-child{ 
  margin-top: 15px;
  line-height: 24px;
  font-size: 14px;
}


.dispute__settlement--buttons {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.dispute__settlement--buttons button {
  display: inline-block;
  padding: 13px 15px;
  cursor: pointer;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  width: 13%;
}

.dispute__transaction--receipt p {
  margin-top: 20px;
}

.dispute__transaction--receipt img {
  width: 60%;
  margin: 35px 0 25px 0;
}

.disputelink__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.disputelink {
  padding: 10px 15px;
  outline: none;
  border: none;
  background-color: var(--Dillivry-Green);
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
}


.loader__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dispute__input--parent {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}


.dispute__error-message {
  color: red;
  font-size: 13px;
  font-weight: 400;
}