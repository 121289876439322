.corp_table {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px 0;
}

.btnPlusChip {
  display: flex;
  gap: 15px;
  align-items: center;
}

.filterBtn {
  position: relative;
  padding: 10px 15px;
  user-select: none;
  border: 0.5px solid #B0AFB0;
  border-radius: 5px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 170px;
  cursor: pointer;
  user-select: none;
}

.filterCont {
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  width: 220px;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 4px;
}

.filterOption {
  padding: 7px 12px;
  cursor: pointer;
  user-select: none;
  display: flex;
  gap: 10px;
  align-items: center;

}

.selectors {
  color: #B0AFB0;
}

.catergoryFiltered {
  padding: 2px 0;
  user-select: none;
  border: 0.5px solid #E7E7E7;
  border-radius: 3px;
  display: flex;
  /* gap: 10px; */
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  user-select: none;
}

.chipss {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  background-color: #0078FC;
  color: white;
  gap: 10px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 20px;
  cursor: pointer;
  user-select: none;
}

.corpSearchHead {
  font-weight: 700;
  font-size: 13px;
}

.corpTableLogo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.corpSearchText {
  font-weight: 400;
  font-size: 13px;
  color: #484848;
}

.corp_hd_table {
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #E7E7E7;
  padding: 13px 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.corp_hd_body {
  cursor: pointer;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #E7E7E7;
  padding: 5px 30px;
  display: flex;
  align-items: center;
}

.corp_hd_body:hover {
  background-color: rgb(253, 251, 251);

}


.input__search {
  width: 100%;
  padding: 12px;
  font-size: 13px;
  outline: none;
  resize: none;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 20px;
}


@media screen and (min-width: 850px) {
  /* .more_data_btn {

    position: absolute !important;
    bottom: 0 !important;
    right: 50 !important;
    top: 800 !important;
  } */
}