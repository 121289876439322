.LoginContainer {
  /* background-image: url("../../assets/backgroundCircle.png"); */
  background-color: #AFAFAF;

  background-repeat: no-repeat;
  background-size: 350px;
  background-position-x: 100%;
  background-position-y: 0px;
}
.LogoContainer {
  height: 80px;
  padding-top: 80px;
}
.Form-Image {
  /* background-color: coral; */
  height: calc(100vh - 80px);
}
.LoginForm {
  /* background-color: brown; */
  /* background-image: url("../../assets/Dlogo.svg"); */
  /* padding: 0px 100px 100px 100px; */
  background-repeat: no-repeat;
  background-position-x: 100px;
  background-size: contain;
}

.formCont {
  display: flex;
  flex-direction: column;
}
.formCont > .formInput {
  margin-bottom: 5%;
}
.LoginHeader {
  font-weight: 600;
  margin-bottom: 100px;
  margin-top: -100px;
  font-size: 36px;
  color: white;
  /* padding-top: 40px; */
}
.GoogleCon {
  width: 100%;
  height: 50px;
  border: 1px solid var(--main);
  border-radius: 5px;
  background-color: #ffff;
  margin-bottom: 5%;
  position: relative;
  color: var(--main);
}
.GoogleCon:hover {
  /* background-color: var(--main);
  color: #ffff;
  border: none; */
  transform: scale(1.02);
  cursor: pointer;
}
.EmailSignin {
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
}
.EmailSignin img {
  /* width: 50%; */
  width: 100%;
}
.LogoContainer {
  padding-left: 100px;
}
.FormInput-Button {
  width: 80%;
  padding-left: 100px;
  /* background-color: chartreuse; */
  /* height: 100%; */
}
.passwordSave {
  margin-bottom: 5%;
}
.lgBtnn {
  margin-bottom: 5%;
}

.parent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.RememberMe:hover {
  cursor: pointer;
  color: var(--main);
}
.ForgotPass {
  text-decoration: none;
  color: #004DC1;
}

.ForgotPass:hover {
  cursor: pointer;
  /* color: var(--main); */
  text-decoration: underline;
}
.btnlabe {
  display: flex;
  justify-content: center;
  align-items: center;
}
.createAccount {
  color: #004DC1;
}
.createAccount:hover {
  /* color: black; */
  text-decoration: underline;
  cursor: pointer;
}
.contact_input{
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 30px;
}
.contact_input label{
  font-weight: 500;
  font-size: 20px
}
.contact_input input{
  outline: none;
  border: 1px solid #8F92A1;
  border-radius: 7px;
  padding: 17px 20px  ;
  background: white;
  font-size: 16px;
}
.contact_input_pass{
  display: flex;
  
  flex-direction: column;
  gap: 7px;
  margin-bottom: 30px;
}
.contact_input_pass label{
  font-weight: 500;
  font-size: 20px
}
.contact_passw{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  
  width: 100%;
  border: 1px solid #8F92A1;
  border-radius: 7px;
  background: white
}
.contact_passw input{
  width: 90%;
  outline: none;
  border: 0;
  padding: 17px 20px  ;
  border-radius: 7px;
  background:white;
  font-size: 16px;

}