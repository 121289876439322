.vpopup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 350;
    background-color: rgba(0, 0, 0, 0.2);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .vpopup-inner {
    position: relative;
    width: 550px;
    background-color: white;
    border-radius: 10px 10px 10px 10px;
    /* margin-top: 50px; */
    /* height: 450px; */
  }
  .headingg {
    padding: 20px 30px;
    height: 35px;
    line-height: 40px;
    display: flex;
    justify-content: space-between;
  }
  .acctTyp {
    font-style: normal;
    font-weight: 600;
    color: #474848 ;
    font-size: 16px;
    letter-spacing: 0.5px;
  }
  .clsBtn {
    margin-right: 20px;
    padding-top: 5px;
  }
  @media screen and (max-width: 1024px) {
    .popup-inner {
      position: relative;
      width: 80%;
      background-color: white;
      border-radius: 10px 10px 0px 0px;
    }
  }