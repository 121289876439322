/* .search-input > input {
  width: 40%;
  height: 39px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: 4px;
  text-indent: 10px;
  position: fixed;
  top: 20px;
  z-index: 10000;
  left: 25%;
  outline-color: var(--Dillivry-Green);
} */



.search-input>div {
  /*
  background: #ffffff;
  border: 1px solid #e7e7e7;


  */
  width: 30%;
  height: 39px;
  box-sizing: border-box;
  border-radius: 4px;
  text-indent: 10px;
  position: fixed;
  top: 20px;
  z-index: 10000;
  left: 25%;
  outline-color: var(--Dillivry-Green);
}


.clearbtn {
  width: 20%;
  height: 39px;
  box-sizing: border-box;
  border-radius: 4px;
  text-indent: 10px;
  position: fixed;
  top: 30px;
  z-index: 10000;
  right: 25%;
  outline-color: var(--Dillivry-Green);
  cursor: pointer;
}

.clearmainbtn {
  height: 40px;
  padding: 2px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  cursor: pointer;

}



.nametrans {
  text-transform: capitalize;
}

.Acct-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: var(--Dillivry-Green);
}


.searchandclear {
  /* display: flex; */

}