.message-right {
  position: relative;
  background: #50b14a;
  text-align: left;
  min-width: 20%;
  max-width: 40%;
  padding: 10px 15px;
  border-radius: 6px;
  /* border: 1px solid #ccc; */
  float: right;
  right: 50px;
  color: white;
}
.message-left {
  position: relative;
  background: #f2f2f7;
  text-align: left;
  min-width: 20%;
  max-width: 40%;
  padding: 10px 15px;
  border-radius: 6px;
  /* border: 1px solid #ccc; */
  float: left;
  left: 10px;
}

.imageView-cropper img {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.message-left::before {
  content: "";
  position: absolute;
  visibility: visible;
  top: -1px;
  left: -10px;
  /* border: 10px solid transparent; */
  border-top: 10px solid #ccc;
}

.message-left::after {
  content: "";
  position: absolute;
  visibility: visible;
  top: 0px;
  left: -8px;
  border: 10px solid transparent;
  border-top: 10px solid #f2f2f7;
  clear: both;
}

.message-right::before {
  content: "";
  position: absolute;
  visibility: visible;
  top: -1px;
  right: -10px;
  border-top: 10px solid #ccc;
}

.message-right::after {
  content: "";
  position: absolute;
  visibility: visible;
  top: 0px;
  right: -8px;
  border: 10px solid transparent;
  border-top: 10px solid #50b14a;
  clear: both;
}
.right-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 20px;
}
.left-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
}
.message-timeStamp {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 10px;
}
