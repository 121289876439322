.navbar {
  padding: 0px 20px;
}

.logo {
  cursor: pointer;
}

.navbar-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-text {
  display: flex;
  align-items: center;
}

.navbar-text > div {
  padding: 10px;
}

.navbar-input-main {
  display: none;
}

.back-to-dashboard {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #50b14a;
  cursor: pointer;
}

.navbar-user-name-text a {
  color: #000;
}
