table {
    width: 100%;
    /* border-collapse: collapse; */
    background: #ffffff;
    border-spacing: 0;
    border-top: 1px solid #eaeded;
    /* border: 1px solid #ffffff; */

}


.table_container {
    box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
    background: #ffffff;
}


thead {
    background: whitesmoke;
    /* border-top: 1px solid #ddd; */
    /* padding: 0; */
}




tr th {
    font-family: 'Amazon Ember', sans-serif;
    color: #545b64;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
}

th,
td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #eaeded;
}

th {
    background-color: #fafafa;
}

tr:hover {
    background-color: #f5f5f5;
}

.pagination {
    display: flex;
    justify-content: center;
}

.pagination button {
    margin: 0 10px;
}

.pagination span {
    margin: 0 10px;
}