@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.drill-down-main {
  margin: 0 auto;
  width: 80%;
}

.drill-down-top {
  margin: 0 auto;
  width: 100%;
  padding: 0 40px;
  position: fixed;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
}

.main-grand {
  background-color: whitesmoke;
  min-height: 100vh;
}

.drill-down-main-container {
  padding: 190px 60px 40px 60px;
  margin: 0 auto;
  width: 100%;
  height: 100vh;
}

.css-480o17-MuiGrid-root>.MuiGrid-item {
  width: 100%;
}

.cards_container {
  width: 100%;
  max-width: 400px;
  display: flex;
  gap: 10px;
  padding: 35px;
  background-color: white;
  border-radius: 5px;
}

.verify_name {
  font-size: 16px;
  font-weight: 500;
}

.verify_flex {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  margin-top: 10px;
  font-weight: 500;
}

.tab_flex {
  color: #484848;
  cursor: pointer;
  user-select: none;
}

.tab_flex:hover {
  text-decoration: underline;
}

.tab_flex_active {
  color: var(--main);
  cursor: pointer;
  user-select: none;
  position: relative;
}

.tab_flex_active::before {
  content: '';
  position: absolute;
  bottom: -12px;
  width: 100%;
  border-radius: 5px;
  height: 5px;
  background-color: var(--main);
}

.move_tabs {
  width: 100%;
  max-width: 700px;
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  align-items: center;
  border-bottom: 1px solid #E7E7E7;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
}

.move_profile_container {
  width: 100%;
  max-width: 700px;
  padding: 20px 40px;
  background-color: white;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
}

.move_profile_container_smaller {
  max-width: 800px;

}

.content_flex {
  width: 100%;
  display: flex;
  padding: 14px 0;
  justify-content: space-between;
  border-bottom: 1px solid #E7E7E7;
  font-size: 13px;
  color: #484848;
}

._last {
  border-bottom: none;
}

.bold_span {
  font-weight: 600;
}

.cursor_span {
  cursor: pointer;
  user-select: none;
}

.cursor_span:hover {
  text-decoration: underline;
}

.blue_span {
  color: blue;
  font-weight: 500;
}

.feedback_container {
  width: 100%;
  max-width: 900px;
  background-color: white;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
}

.feedback_ {
  width: 100%;
  padding: 16px 30px;
  border-bottom: 1px solid #E7E7E7;
  font-size: 13px;
  color: #484848;
}

.feed_heading {
  font-weight: 600;
  font-size: 13px;
}

.feed_body {
  font-weight: 500;
  font-size: 13px;
}

.documents {
  background-color: #ffffff;
  border: 2px solid #e6f4e5;
  box-sizing: border-box;
}

.inner-documents {
  display: flex;

  margin: 20px;
  flex-direction: column;
  /* gap: 20px; */
}

.profile-photo {
  display: flex;
  gap: 10px;
  align-items: center;
  background: #ffffff;
  border-bottom: 1px solid #E7E7E7;
  box-sizing: border-box;
  color: var(--main);
  font-size: 13px;
  padding: 20px;
  width: 100%;
  cursor: pointer;
  user-select: none;
}

.profile-photo-pending {
  display: flex;
  gap: 10px;
  align-items: center;
  background: #ffffff;
  border-bottom: 1px solid #E7E7E7;
  box-sizing: border-box;
  color: #444343;
  font-size: 13px;
  padding: 20px;
  width: 100%;
  cursor: pointer;
  user-select: none;
}

.profile-photo-reject {
  display: flex;
  gap: 10px;
  align-items: center;
  background: #ffffff;
  color: #444343;
  font-size: 13px;
  border-bottom: 1px solid #E7E7E7;
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  cursor: pointer;
  user-select: none;
}


.imgage-text {
  display: flex;
  /* flex-grow: 1;
   flex-basis: 0; */
  justify-content: center;
  flex-direction: column;
}

.imgage-text {
  display: flex;
  justify-content: center;
}

.imgage-text>img {
  width: 138px;
  height: 108px;
}

.pop-up-content {
  margin: 0 auto;
  /* width: 50%; */
  padding: 20px;
}

.pop-up-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  align-self: center;
}

.content {
  padding: 20px;
}
.caution_card{
  border: 1px solid gray;
  padding: 10px 8px;
  display: flex;
  align-items: flex-start;
  gap: 5px;
  border-radius: 5px;
}
.caution_card div{
  line-height: normal;
  font-size: 14px;
  font-weight: 500;
}
.cofirm-text{
  line-height: normal;
  font-size: 14px;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 4px;
}
.btn-parent {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  padding: 0 30px;
  padding-top: 15px;

  gap: 15px;
  border-top: 1px solid rgb(195, 195, 195);
}
.perm-input{
  width: 100%;
  padding: 5px 10px;
  font-size: 13px;
  outline: none;
  border: 1px solid gray;
  border-radius: 3px;

}
.perm-input::placeholder{
  font-style: italic;
}

.success {
  background: #50b14a;
  border-radius: 4px;
  width: 50px;
  height: 25px;
  color: #ffffff;
  outline: none;
  border: #50b14a;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reject {
  background: #c54b4b;
  border-radius: 4px;
  width: 50px;
  height: 25px;
  color: #ffffff;
  outline: none;
  border: #c54b4b;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled {
  background: #b0afb0;
  border-radius: 4px;
  width: 50px;
  height: 25px;
  color: #444343;
  outline: none;
  border: #b0afb0;
  cursor: pointer;
}

.deleter {
  background: #b23715;
  border-radius: 4px;
  width: 80px;
  height: 30px;
  color: white;
  outline: none;
  border: none;
  font-weight: 500;
  /* border: #50b14a; */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleter_disable {
  background: #cdc2be;
  border-radius: 4px;
  width: 80px;
  height: 30px;
  color: white;
  outline: none;
  border: none;
  font-weight: 500;
  /* border: #50b14a; */
  cursor: not-allowed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.canceller {
  background: white;
  border-radius: 4px;
  width: 80px;
  height: 30px;
  color: black;
  outline: none;
  border: none;
  font-weight: 500;
  /* border: #50b14a; */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pending {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #50b14a;
  margin-top: 20px;
}

.content {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #444343;
}

.reset_pass_con {
  padding-top: 30px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.reset_pass_btn1 {
  padding: 7px;
  background-color: white;
  border: 1px solid var(--main);
  color: var(--main);
  border-radius: 4px;
}

.reset_pass_btn1:hover {
  background-color: var(--main);
  color: white;
  cursor: pointer;
  /* transform: scale(1.1); */
}

.reset_pass_btn2 {
  padding: 7px;
  border-radius: 4px;
  background-color: var(--main);
  color: white;
  border: 1px solid var(--main);
  cursor: pointer;
  width: 70px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}