@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.container {
  display: grid;
  grid-template-areas:
    "header header"
    "nav content ";
  grid-template-columns: 265px 1fr;
  grid-template-rows: auto 1fr;
}
.css-1b8dpa0{
  background-color: whitesmoke !important;
}
main {
  /* height: 100vh; */
  padding: 20px;
  grid-area: content;
  background: #ffffff;
}
.Item_container{
  cursor: pointer;
  user-select: none;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  filter: drop-shadow(0px 1.02813px 3.08438px rgba(16, 24, 40, 0.1)) drop-shadow(0px 1.02813px 2.05625px rgba(16, 24, 40, 0.06));
  border-radius: 4px;
  padding: 20px;
  text-transform: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.it_tem {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}
.it_tem h2{
  font-weight: 400;
font-size: 26.675px;
}

.Item_container:hover{
  border: 1px solid var(--Dillivry-Green);
}

.Item > div {
  flex-grow: 4;
}

.manage- {
  display: flex;
  justify-content: flex-end;
}

.inner-item {
  display: flex;
}

.item-icon {
  margin-right: 10px;
}

.item-title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1d1c1d;
}

.item-desc {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 24px;
  color: #969696;
}

.Item > span {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #1d1c1d;
  font-size: 10px;
}

.Item > span:hover{
  color: var(--Dillivry-Green);
}

.chartHome{
width: 100%;
height: 100%;
filter: drop-shadow(0px 1.02813px 3.08438px rgba(16, 24, 40, 0.1)) drop-shadow(0px 1.02813px 2.05625px rgba(16, 24, 40, 0.06));
border-radius: 4px;
background: #ffffff;
  border: 1px solid #ffffff;
  position: relative;
}

.percentagediv{ 
position: fixed !important;
/* margin-left: auto;
margin-right: auto;
left:  0;
right: 30%;
text-align: center; */
width: 100%;
max-width: 100px !important;
}


.piecont{
  position: relative !important;
}


.centerlabel{
  color: aqua;
  font-size: 32px
  
}



.percentagediv2{
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  background: #1d1c1d;
  color: #E7E7E7;
}




.chartHead{
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #EBEFF2;
}
.chartTit{
  font-weight: 500;
font-size: 16px;
}
.chartHead span{
  font-size: 14px;
}
.chartConts{
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

}

.recharts-wrapper{
position: relative;


}


.radioQuoteHpuse{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.radioQuote{
  display: flex;
  gap: 10px;
  align-items: center;

}
.radios{
  width: 13px;
  height: 13px;
  border-radius: 50px;
}
.labeltext{
  font-weight: 400;
font-size:10px;
color: #192A3E;
}
.trendChart{
  width: 100%;
  filter: drop-shadow(0px 1.02813px 3.08438px rgba(16, 24, 40, 0.1)) drop-shadow(0px 1.02813px 2.05625px rgba(16, 24, 40, 0.06));
  border-radius: 4px;
  border: 1px solid #ffffff;
  background: #ffffff;
  height: 450px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.trendHeadre{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.chartHomey{
  flex: 1;
  width: 100%;
height: 450px;
background-color: white;
padding: 20px;
}
.searchHead{
  font-weight: 700;
font-size: 13px;
}
.searchText{
  font-weight: 400;
font-size: 13px;
color: #484848;
}
.hd_table{
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #E7E7E7;
  padding: 10px 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.hd_body{
  cursor: pointer;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #E7E7E7;
  padding: 10px 20px;
}
.hd_body:hover{
  background-color: rgb(253, 251, 251);

}
.actIcon{
  color: #484848;
  cursor: pointer;
}
.actIcon:hover{
  color: var(--main);
}
