.quoted_view_con {
  width: 100%;
  background: #f9f9f9;
  padding: 20px;
}

.qoute-nav {
  border-bottom: 1px solid #c7e5c5;
}

.delivery-wrapper {
  padding: 20px;
}

.topper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 20px 0;
}

.view_Map {
  width: 100%;
  height: 400px;
  background-color: #eaf4ea;
  border-radius: 5px;
}

.google__map--container{
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.item_detail {
  width: 100%;
  padding: 15px 0;
}

.item_imges_con {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item_imges {
  width: 100%;
  height: 300px;
  max-width: 350px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  margin-bottom: 10px;
}

.item_inform {
  width: 100%;
}

.topTitle {
  display: flex;
  gap: 10px;
  width: 100%;
  background-color: var(--main);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid var(--main);
  padding: 5px 10px;
  color: white;
}

.itemDescs {
  width: 100%;
}

.itemDesc {
  height: 45px;
  padding: 10px;
  border-bottom: 1px solid #e5e5e5;
  border-top: 0;
  display: flex;
  gap: 20px;
  font-size: 12px;
  justify-content: space-between;
  align-items: center;
}

.itemDesc2 {
  padding: 16px;
  border-bottom: 1px solid #e5e5e5;
  border-top: 0;
  display: flex;
  gap: 15px;
  font-size: 12px;
}

.itemDescTop {
  border-top: 1px solid #e5e5e5;
}

.quote_data_con {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.quote_data {
  width: 70%;
  max-width: 350px;
}

.sender_data_con {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.sender_data {
  width: 70%;
  max-width: 350px;
}

.tableCard {
  width: 100%;
}

.paginationHead {
  width: 100%;
  padding: 0 20px;
  border: solid 1px var(--main);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 5px;
}

.paginationHead span {
  font-weight: bold;
  font-size: 16px;
}

.quoteee {
  width: 100%;
}

.image-quote {
  width: 100%;
  height: 100%;
  display: flex;
  /* align-items: center; */
}

.best-quote {
  width: 70%;
  max-width: 350px;
  background-color: #f6f6f6;
  border: 1px solid #e7e7e7;
}

.quote-header {
  background-color: #e6f4e5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: var(--main);
  border: 1px solid #e7e7e7;
}

.listss {
  list-style: none;
}

.qoute-price {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e7e7e7;
}

.image-details {
  display: inline-flex;
  padding: 5px 10px;
}

.box-lisst {
  padding-left: 20px;
}

.insure {
  padding: 5px;
}

.btn- {
  background: #ffffff !important;
  color: var(--main) !important;
  cursor: pointer;
}

.toolTips2 {
  display: flex;
  align-items: center;
}

.update_itemdesc_con {
  display: flex;
  justify-content: center;
  align-items: center;
}

.update_itemdesc_form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.update_itemdesc_text {
  padding: 15px;
}

.update_itemdesc_btncon {
  display: flex;
  justify-content: flex-end;
}

.update_itemdesc_btn {
  padding: 7px;
  border-radius: 4px;
  background-color: var(--main);
  color: white;
  border: 1px solid var(--main);
  cursor: pointer;
  width: 70px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
  margin-bottom: 20px;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

.select-search__options {
  list-style: none;
  margin: 0;
  padding-inline-start: 0;
}

.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: var(--main);
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.global-class-name-icon {
  cursor: pointer;

  background: transparent;

  padding: 3px;
}

.assignandpayment {
  display: flex;
  gap: 20px;
}

/* Button Swipper*/
.arrow__swipe {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 15%;
}

.arrow__swipe--left,
.arrow__swipe--right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.arrow__icon {
  width: 24px;
  height: 25px;
  color: #555;
  cursor: pointer;
}

.arrow__swipe p {
  font-size: 13px;
}

/* Quote table */
.quote__table--container {
  margin-top: 60px;
  background: #fff ;
  padding: 40px;
}

.quote__table--container h3 {
  margin-bottom: 20px;
  font-weight: 400;
}

.quotehistory__table {
  width: 100%;
  border-collapse: collapse;
}

.quotehistory__table th,
.quotehistory__table td {
  padding: 8px;
}

.quotehistory__table th {
  border-right: 1px solid #ccc;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}

.quotehistory__table td {
  font-size: 14px;
  padding: 20px;
}

.quotehistory__table tr {
  margin-bottom: 40px;
}

.quote__provider--link a {
  color: #555;
  color: var(--Dillivry-Green);
}

.quotehistory__table tr:nth-child(even) {
  background-color: rgb(255, 255, 255);
}