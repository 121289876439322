.image-cropper img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.message-name {
  padding-left: 10px;
}
.image-name {
  display: flex;
  align-items: center;
}

.table--img {
  width: 10%;
  border-radius: 10%;
  max-width: fit-content;
}

.image--table {
  width: 40%;
}