@import 'flag-icon-css/css/flag-icons.min.css';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-size: 12px; */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
  font-family: Roboto;
}

:root {
  --main: #50b14a;
  --lighty: #73af46;
  --lightMain: rgb(236, 252, 242);
  --lighter: #ffffff;
  /* --lighest: #E0DFE0; */
  --lighest: rgb(236, 252, 242);
  --msgBg: rgb(243, 252, 246);
  --msgBorderline: rgb(187, 216, 197);
  --mline: rgb(79, 179, 114);

  --white: #ffffff;
  --darkest: #292929;
  --darker: #999999;
  --dark: #545454;

  /* --dark: #81D1FF; */

  box-sizing: border-box;
  padding: 0;
  margin: 0;
}


.css-zw3mfo-MuiModal-root-MuiDialog-root {
  position: fixed !important;
  z-index: 1200 !important;
}

.css-rtrcn9-MuiTablePagination-root:last-child {
  /* padding-right: 80px !important; */
}


/* @media screen and (min-width: 850px) {
  .more_data_btn {

    position: absolute !important;
    bottom: 0 !important;
    right: 50 !important;
    top: 2000 !important;
  }
} */