@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.dashboard {
    background: #E6F4E5 !important;
    height: 61px !important;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #50B14A;
}


.mybtn {
    position: absolute;
    bottom: 100px;
    padding: 20px;
}

.mybtn>.btn- {
    background: var(--Dillivry-Green);
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-transform: none;
}

.mybtn>.btn-:hover {
    background: var(--Dillivry-Green);
    font-size: 16px;
    line-height: 19px;
}

.css-1kklrhw-MuiList-root {
    padding-top: 0px !important;
}

.active_class {
    background-color: #50B14A !important;
    color: #FFFFFF !important;
}


.dashboard__container {
    box-sizing: border-box;
    border-top: none;
    margin-top: 75px;
    padding-top: 0;

}

.side-nav-container {
	/* background-color: #061b28; */
	width: 250px;
	height: 100vh;
	position: relative;
	color: black;
    margin-top: 100px;
	transition: 0.4s;
}


.side-nav-container-NX {
	width: 85px;
    color: #000;
}


.nav-upper,
.nav-heading,
.nav-menu,
.menu-item,
.nav-footer {
	/* border: 2px solid white; */
	display: grid;
}

.nav-heading {
	grid-template-columns: 2fr 1fr;
	grid-template-rows: 1fr;
	height: 75px;
}

.nav-brand {
	display: flex;
	color: #fff;
}
.nav-brand img {
	width: 40px;
	padding: 0 10px;
}

.hamburger {
	background: none;
	border: none;
	cursor: pointer;
	margin: auto 20px;
    position: absolute;
    right: 0;
    /* top: -px; */
}

.hamburger span {
	display: block;
	margin-top: 5px;
	background-color: #000;
	border-radius: 5px;
	height: 2px;
	width: 28px;
	transition: 0.4s;
}

.hamburger:hover span {
	background-color: var(--Dillivry-Green);
}


.hamburger-out {
	margin-left: 24px;
}
.hamburger-out:hover span:nth-child(1) {
	width: 25px;
	transform: translate(14px, 4px) rotate(-155deg);
}
.hamburger-out:hover span:nth-child(2) {
	width: 40px;
}
.hamburger-out:hover span:nth-child(3) {
	width: 25px;
	transform: translate(14px, -4px) rotate(155deg);
}

.nav-menu {
	grid-template-rows: repeat(11, 1fr);
	margin-top: 30px;
    padding-top: 38px;
}

.menu-item {
	/* height: 57px; */
	display: flex;
	color: #000;
	text-decoration: none;
	margin: auto 20px;
    font-weight: 400;

}

.menu-item-NX {
	margin: auto;
    margin-bottom: 30px;
}

.sidebar__icon {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}


.sidebar__icon div {
	width: 40px;
	padding: 0 20px;
}

.sidebar__icon p:hover {
    color: var(--Dillivry-Green);
}

.nav-footer {
	position: absolute;
	bottom: 0;
	grid-template-rows: 1fr;
	grid-template-columns: 2fr 1fr;
    margin: auto 20px;
    padding-bottom: 10px;
}

.nav-details {
	display: flex;
}
.nav-details img {
	width: 50px;
	padding: 0 20px;
}

.nav-footer-user-name {
	font-size: 18px;
	font-weight: 900;
}

.nav-footer-user-position {
	margin-top: -15px;
	color: #756388;
}
.logout-icon {
	width: 30px;
	margin: auto;
	border-radius: 90px;
	padding: 20px;
	margin-left: 5px;
}
.logout-icon:hover {
	background-color: #0091ff;
}

.mybtn {
    margin-top: 60px;
}