/* .disputedrilldown__container {
  padding: 30px 20px;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
} */

.dispute__heading p {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 30px;
}

.dispute__card--personal {
  margin-bottom: 20px;
}

.dispute__accordion--summary h3 {
  font-weight: 400;
  font-size: 15px;
}

.dispute__details--info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding-bottom: 6px;
}

.dispute__details--info p {
    font-size: 14px;
}

.dispute__details--info p:last-child {
    justify-content: flex-end;
    margin-top: 15px;
}

.dispute__details--button, .dispute__action--buttons {
    border: none;
    background: var(--Dillivry-Green);
    margin-top: 15px;
    font-size: 13px;
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.dispute__info--modal {
  display: flex;
  flex-direction: column;
}

.dispute__info--modal h2{
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 25px;
  text-align: center;
}

.dispute__info--details {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 300;
}

.dispute__info--para {
  font-weight: 300;
}


.dispute__info--modal-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: var(--Dillivry-Green);
  color: #fff;
  font-size: 14px;
  margin-top: 20px;
  width: fit-content;
  cursor: pointer;
}


.disputedrilldown__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  width: 650px;
  border-radius: 5px;
  margin: 80px auto;
  padding: 30px;
  background-color: #fff;
}

.disputedrilldown__container h2 {
  font-size: 22px;
  font-weight: 300;
  text-align: center;
}

.disputedrilldown__info {
  width: 100%;
}

.disputedrilldown__child {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.3px solid #ccc;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.disputedrilldown__child--image p{
  margin-bottom: 10px;
}

.disputedrilldown__child--image img  {
  margin-bottom: 30px;
  width: 70%;
}

.disputedrilldown__child--text  {
  border-bottom: 0.3px solid #ccc;
  margin-bottom: 20px;
}

.disputedrilldown__child--text p {
  margin-bottom: 15px;
}
.disputedrilldown__child--text p:last-child {
  font-size: 13.7px;
}

.disputedrilldown__child p,
.disputedrilldown__child--text p {
  font-size: 14px;
}

.disputedrilldown__container button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: var(--Dillivry-Green);
  color: #fff;
  font-size: 14px;
  margin-top: 20px;
  width: fit-content;
  cursor: pointer;
}