.cFrorm {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.deletBtn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.btnlabel {
  font-size: 14px;
}

Button.btnnn:hover {
  background-color: #fff;
  color: var(--Dillivry-Green);
  border: 1px solid #50b14a;
}

Button.btnnn {
  width: 30%;

  margin-top: 20px;

  border-radius: 4px;
  padding: 8px;
  text-transform: none;
  height: 25px;

  background-color: var(--Dillivry-Green);
  color: #fff;
}

Button.btnnn2 {
  background-color: #fff;
  color: var(--Dillivry-Green);
  border: 1px solid #50b14a;
  width: 30%;

  margin-top: 20px;

  border-radius: 4px;
  padding: 8px;
  text-transform: none;
  height: 25px;
}


.send-btnnn {
  margin-top: 20px;
  border-radius: 4px;
  padding: 8px;
  text-transform: none;
  /* height: 25px; */
  background-color: var(--Dillivry-Green);
  color: #fff;
  border: 1px solid var(--Dillivry-Green);
}