.InputField .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl {
  height: 40px;
}
.InputField .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
  height: 40px;
  font-size: 14px;
}
.InputField .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
  display: flex;
  align-items: center;
  top: -5px;
  font-size: 14px;
}
