.breadcrumbs {
  max-width: 1200px;
}
.breadcrumbs > *{
  display: inline-block;
  margin-right: 15px;
}
.breadcrumbs .crumb:after {
  content: ' > ';
  margin-left: 10px;
}
.breadcrumbs .crumb:last-child:after {
  display: none;
}
.breadcrumbs .crumb a {
  color: #0073bb;
  cursor: pointer;
}